import "./RecentUsersDB.css";
import MenuBar from "./MenuBar";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RecentUsersDB() {
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const usenavigate=useNavigate();
  const params = useParams();
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchData();
    fetchOptions();
  }, []);
  const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/mhs/${params.id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': Cookies.get('token'),
          },
          withCredentials: false, 
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        
        setRows(data.data);
        setPeriod(data.periode);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const fetchOptions = async () => {
      try {
        const response = await fetch(`${apiUrl}/kelas`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': Cookies.get('token'),
          },
          withCredentials: true, 
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleChange = (event) => {
    setSelectedId(event.target.value);
  };

  const handleNavigate = () => {
    if (selectedId) {
      usenavigate(`/adminlogin/recentusersdb/${selectedId}`); 
      window.location.reload();
    }
  }
  
  const updateRowStatus = async(mhs_id) => {
    const setStatus = await fetch(`${apiUrl}/mhs/status/${mhs_id}`, {
      method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          status: 'terdaftar',
        })
    });

    if(!setStatus.ok){
      throw new Error('Failed to add class: ' + setStatus.statusText);
    }

    window.location.reload();
  };

  const rejectRowStatus = async(nim) => {
    const setStatus = await fetch(`${apiUrl}/mhs/status/${nim}`, {
      method: "PATCH",
        headers: {
          'Accept': 'application/json',
          'Authorization': Cookies.get('token'),
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          status: 'tertolak',
        })
    });

    if(!setStatus.ok){
      throw new Error('Failed to add class: ' + setStatus.statusText);
    }

    window.location.reload();
  };

  const statusMap = {
    'proses': 'Proses',
    'tertolak' : 'Tertolak',
    'terdaftar' : 'Terdaftar'
  };
  
  const getFormattedStatus = (status) => {
    return statusMap[status] || 'Unknown';
  };

  const StatusCell = ({ value }) => {
    const color = value === "terdaftar" ? 'green' : (value === "proses" ? '#fdc500' : 'rgb(221, 24, 24)');
    const border = value === "terdaftar" ? '1px solid green' : (value === "proses" ? '1px solid #fdc500' : '1px solid rgb(221, 24, 24)');
    const fontWeight = value === 0 ? '450' : '450';
    return (
      <div style={{ color, border, textAlign: 'center', fontWeight, borderRadius: '12px', padding: '5px 5px',  width: '90px',}}>
        {getFormattedStatus(value)}
      </div>
    );
  };
  
  const handleOpen = (imagePath) => {
    setSelectedImage(imagePath);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 215,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className="cellAction">
            <div
              className="approveButton"
              onClick={() => updateRowStatus(row.mhs_id, "Approved")}
            >
              Approve
            </div>
            <div
              className="rejectButton"
              onClick={() => rejectRowStatus(row.mhs_id, "Rejected")}
            >
              Reject
            </div>
          </div>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => {
        const row = params.row;
        return (
          <div className="cellAction">
            <button onClick={() => handleOpen(row.path)} className="openButton">
              Open
            </button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                {selectedImage ? (
                  selectedImage.toLowerCase().endsWith(".pdf") ? (
                    <iframe
                      src={selectedImage}
                      title="Bukti Pembayaran"
                      style={{ display: "block", margin: "auto" }}
                      height="500px"
                      width="100%"
                    />
                  ) : (
                    <img
                      src={selectedImage}
                      alt="Bukti Pembayaran"
                      style={{
                        maxHeight: "500px",
                        maxWidth: "100%",
                      }}
                    />
                  )
                ) : (
                  <p style={{ textAlign: "center", marginTop: "50px" }}>
                    Image not found
                  </p>
                )}
              </Box>
            </Modal>
          </div>
        );
      },
    },
  ];

  const columns = [
    { field: "nim", headerName: "NIM", width: 140 },
    { field: "nama", headerName: "Full Name", width: 250 },
    { field: "prodi", headerName: "Prodi", width: 260 },
    { field: "status", headerName: "Status", width: 120, renderCell: (params) => <StatusCell value={params.row.status} /> },
  ];

  return (
    <div className="body">
      {loading ? (
        <div className="LoadingScreen">
          <i>Loading Data <div className="loader"></div></i>
        </div>
      ) : (
        <div>
      <div className="judulDB2">
        <b>| <i>Detail Pendaftar</i></b>
        <p className="textIdInput">
              <i><b>Periode: </b> <div className="txtId">{period}</div></i>
        </p>
      </div>
      <div>
        <select value={selectedId} onChange={handleChange} className="dropdown-db1">
          <option value="">Pilih Periode</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.nama_kelas}
            </option>
          ))}
        </select>
        <button onClick={handleNavigate} disabled={!selectedId} className="btnDD-db1">
          Go to Details
        </button>
      </div>
      <div className="datatable">
        <DataGrid
          rows={rows}
          columns={columns.concat(actionColumn)}
          getRowId={(row) => row.nim}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 9 },
            },
          }}
          pageSizeOptions={[9]}
        />
      </div>
      <MenuBar />
      </div>
      )}
    </div>
  );
}